<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="$t('寶衡传媒')">
      <template #right>
        <img
          src="../../assets/lang.png"
          style="width: 26px; height: 26px"
          @click="showPicker = true"
        />
        <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
      </template></van-nav-bar
    >
    <div class="convention-item">
      <van-swipe
        :autoplay="3000"
        lazy-render
        class="my-swipe"
        :show-indicators="false"
      >
        <van-swipe-item v-for="image in images" :key="image"
          ><img :src="image" class="banner_img"
        /></van-swipe-item>
      </van-swipe>
      <div class="describe">
        <ul>
          <li>
            <div>{{ $t("寶衡传媒") }}</div>
            <div>{{ $t("华人第一福利品牌") }}</div>
          </li>
          <li>
            {{ $t("此網站暫時只展示") }}
            <b style="font-size: 16px; color: #f8c693">10%</b>
            {{
              $t(
                "資源，並且不定期更新只為展示實力，如需查詢預約登記更多女仔，請聯繫你的接待員"
              )
            }}
          </li>
        </ul>
        <div>
          {{
            $t(
              "本平台是一个主播平台，所有女孩都经过实名认证，真实丶安全丶可靠，每个区域都可以提供配对，选择一个心仪的她，陪你零距离邂逅，快点来加入吧!"
            )
          }}
        </div>
      </div>

      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab :title="$t('寶衡资源')">
          <!-- <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div> -->
          <!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
          <div
            class="xuanfei"
            v-for="(val, key) in xuanfeilist"
            :key="key"
            @click="xfts(val.id)"
          >
            <div class="left-div">
              <div>ID:{{ val.itemid }}</div>
              <div>
                <ul>
                  <li>{{ $t("昵称") }}： {{ val.nickname }}</li>
                  <li>{{ $t("类型") }}：{{ $t(val.typelabel) }}</li>
                  <li>{{ $t("来自") }}：{{ $t(val.region) }}</li>
                  <li>{{ $t("服务项目: 激活可见") }}</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
                      alt=""
                    />
                    <span>{{ val.views }}</span>
                  </li>
                  <li>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
                      alt=""
                    />
                    <span>{{ val.collection }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="right-div">
              <img :src="domain + val.flagimg" alt="" />
            </div>
            <i class="sign-i">{{ $t("已认证") }}</i>
          </div>
        </van-tab>
        <van-tab :title="$t('实时配对')">
          <div class="scroll-cmp">
            <van-swipe
              :height="80"
              vertical
              :autoplay="5000"
              :duration="1000"
              :show-indicators="false"
              :loop="true"
            >
              <van-swipe-item v-for="(val, key) in peiduilist" :key="key">
                <div>
                  <span>{{ val.name }}</span>
                  <span>{{ val.tel }}</span>
                </div>
                <div>
                  <span>{{ val.title }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </van-tab>
        <van-tab :title="$t('约炮心得')">
          <div class="scroll-cmp">
            <van-swipe
              :height="240"
              vertical
              :autoplay="5000"
              :show-indicators="false"
              :loop="true"
            >
              <van-swipe-item v-for="(val, key) in yuepaolist" :key="key">
                <div>
                  <span>{{ val.name }}</span>
                  <span>{{ val.tel }}</span>
                </div>
                <div>
                  <span>{{ val.title }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </van-tab>
        <van-tab :title="$t('服务条款')">
          <div class="card">
            {{
              $t(
                "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。"
              )
            }}
          </div>
          <div class="rig-box">
            <p class="rig-title">{{ $t("有哪些资源?") }}</p>
            <p class="rig-content">
              {{
                $t(
                  "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到"
                )
              }}
            </p>
            <p class="rig-title">{{ $t("服务范围") }}?</p>
            s
            <p class="rig-content">
              {{ $t("服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩") }}
            </p>
            <p class="rig-content">
              {{ $t("可拍照留念： 私处特写 做爱录屏 口交录屏") }}
            </p>
            <p class="rig-content">
              {{ $t("可指定制服：护士-女仆-空姐-秘书-女警-猫女") }}
            </p>
            <p class="rig-content">
              {{ $t("可捆绑轻虐：配合使用各式男女情趣性爱道具") }}
            </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :title="$t('标题')"
        :columns="columns"
        value-key="key"
        :confirm-button-text="$t('确定')"
        :cancel-button-text="$t('取消')"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domain: this.serviceUrl,
      showPicker: false,
      columns: this.$langArray,
      addlist: [
        {
          0: this.$t("北京"),
          1: this.$t("上海"),
          2: this.$t("广州"),
          3: this.$t("深圳"),
        },
        {
          0: this.$t("南京"),
          1: this.$t("长沙"),
          2: this.$t("重庆"),
          3: this.$t("西安"),
        },
      ],
      images: [
        "img/home/1.jpg",
        "img/home/2.jpg",
        "img/home/3.jpg",
        "img/home/4.jpg",
      ],
      xuanfeilist: [],
      peiduilist: [],
      yuepaolist: [],
    };
  },
  methods: {
    addgo(data) {
      // if (!localStorage.getItem('token')) {
      // 	this.$router.push({ path: '/Login' });
      // } else {
      // 	this.$router.push({
      // 		path: '/list?id=' + data.id + '&name=' + data.name
      // 	});
      // }
      this.$router.push({
        path: "/list?id=" + data.id + "&name=" + data.name,
      });
    },
    onConfirm(value) {
      this.$i18n.locale = value.key;
      localStorage.setItem("language", value.key);
      localStorage.setItem("lang", value.value);
      this.createList();
      this.$bus.$emit("changeLang",1)
      this.showPicker = false;
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "indexlist",
      }).then((res) => {
        this.xuanfeilist = res.data;
      });
    },
    // getPeiduilist() {
    // 	this.$http({
    // 		method: 'get',
    // 		url: 'indexlistpeidui'
    // 	}).then(res => {
    // 		this.peiduilist = res.data;
    // 	});
    // },
    // getYuepaolist() {
    // 	this.$http({
    // 		method: 'get',
    // 		url: 'indexlistyuepao'
    // 	}).then(res => {
    // 		this.yuepaolist = res.data;
    // 	});
    // },
    xfts(id) {
      this.$router.push({ path: "/Detail?id=" + id });
    },
    createList() {
      this.yuepaolist = [
      {
          name: this.$t("Ethan 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "中財平台真的太讚了！朋友介紹我來的，雖然過程有一點麻煩，但是一旦完成了流程，我就約到了她的顏值真的高到爆表！我超級滿意！感謝中財這個平台。"
            ),
        },
        {
          name: this.$t("Liam 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "我真的要感謝朋友介紹我來中財這個平台！雖然有一些瑕疵的過程，但最後結果真的讓我滿意！我和我的約上對象都非常的有魅力和好看！這真的是一個值得信賴的平台！感謝中財，讓我找到了這麼棒的美女！"
            ),
        },
        {
          name: this.$t("Michael 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "中財平台真的是一個很棒的地方！我很感激我的朋友介紹我來這裡！雖然在流程中遇到了一些麻煩，但最後結果超出了我所有的期望！我約的女生非常迷人，她的顏值真的是高得讓人窒息！感謝中財這個平台，給了我這麼美好的機會和緣份"
            ),
        },
        {
          name: this.$t("Benjamin 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "Furthermore, apart from staying at the hotel, girls can engage in activities like shopping, watching movies, attending parties, and spending time with friends, exceeding initial expectations."
            ),
        },
        {
          name: this.$t("Henry  先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "Thanks to Zhongcai, I am very satisfied with meeting a girl, she is very understanding, besides staying in a hotel, you can also go shopping and do some romantic things."
            ),
        },
        {
          name: this.$t("Anson先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "首次嘗試通過平台搵SP.總體嚟講體驗唔錯。女女係真人真相。就係流程有少少麻煩喎。同靚女直接上房過夜後互補干擾各自生活👍"
            ),
        },
        {
          name: this.$t("Andy先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "客服服務態度良好。我揀咗多次，最後揀個啱我心水嘅女仔。有同女仔視訊先，真係搞到我好緊火咁😌 同佢出嚟遊車河跟住直接上房。會推薦俾朋友嘅！"
            ),
        },{
          name: this.$t("Mr Lucas"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "The platform feels very good to me. The girl is very open and there will be no resistance. Prices are also very reasonable. I'll pick a different girl again."
            ),
        },
        {
          name: this.$t("Mr Pete"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "I was introduced by a friend. At first, I didn't quite trust the platform and found the process cumbersome. But I still couldn't help being highly recommended by my friends, but the result was unexpected! My advice is to choose cute girls."
            ),
        },
        {
          name: this.$t("mr charles"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "The girl on this platform is where I propose her a date. She's here for an appointment. Girls are like your real life girlfriends. I had a great time with her. I think through this platform I will get real happiness! Thanks for the platform!"
            ),
        },
        {
          name: this.$t("CHUN 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "已經番約左幾次，囡囡會同你傾下計，引下你，真係比到女朋友既Feel。雖然只係短短一晚约会，但真係好享受，好正"
            ),
        },
        {
          name: this.$t("CHEN 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "睇師兄食評話03囡囡超正, 咁就試吓約佢出嚟, 都真係唔錯, 準時,有耐性, 好傾, 一齊沖涼時嚟個水簫, 差D走火, 可車, 口技中上, 狀度好好，口技了得, 值得一試！"
            ),
        },
        {
          name: this.$t("COOO 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "I am very satisfied with this appointment, the whole process made me feel very happy and warm. First of all, the location was very well chosen, we chose a nice and peaceful cafe. The ambiance of the place is very cozy and the music is soft, allowing us to have a relaxed conversation. Secondly, the other party's dress was very decent, which left a deep impression on me. she keeps a friendly smile all the time, making the whole dating process more pleasant and easy."
            ),
        },
        {
          name: this.$t("Yeung 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "The date experience was very good, we spent a pleasant and memorable evening. I found a strong attraction and resonance between us, and I am looking forward to our future. I hope we can continue to explore and build deeper emotional connections together."
            ),
        },
        {
          name: this.$t("John先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "約過很多次了，每次比人嘅感覺都唔一樣，女仔都係出黎搵刺激嘅比人嘅感覺同拍拖一樣，呢個感覺真係好.感謝中財俱樂部！"
            ),
        },
        {
          name: this.$t("wong先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "I have made many appointments, and each time it gives people a different feeling. Girls always come out to find excitement, just like dating. This feeling is great. Thank you to Zhongcai Club!"
            ),
        },{
          name: this.$t("Steven先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "服務好，文靜，身材好，有經驗"
            ),
        },
        {
          name: this.$t("Boy先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "體驗過幾個俱樂部搵SP，中財係體驗最唔錯嘅一個，呢個俱樂部唔同其佢俱樂部，我驚真人同相唔一樣，佢哋揀好女仔後可視頻認證，唔鐘意可退換，真係唔錯！"
            ),
        },
        {
          name: this.$t("Fish先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "I have been to several clubs to find girls, and Zhongcai is the one I have had the best experience with. This club is different from other clubs, and I am afraid that photos are very different from real people. After I select the girls in this club, I can video certify them, and if I don't like them, I can get free returns and exchanges. It's really not wrong!"
          ),
        },
        {
          name: this.$t("Ming先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "09女孩子太棒了，很聽話，經驗很足，很漂亮，識趣"
            ),
        },
        {
          name: this.$t("Kwong先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "我最鐘意的係細細粒波大大, 38E實力派巨乳中醫按摩Winnie揼骨手勢好，力水夠，穴位精準，踩背都很有水準，恰好到處，很好的一次體驗，沒想到搵SP還有這種。"
            ),
        },
        {
          name: this.$t("Wongb 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("Lees 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
          ),
        },
        {
          name: this.$t("Tangy 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            this.$t(
              "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足"
              )
          ),
        },
        {
          name: this.$t("MOE 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾"
          ),
        },
        {
          name: this.$t("Szea 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
          ),
        },{
          name: this.$t("Wongl 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "今天正好有空閒，正好去驗證一下，就約了一下。見面一看，胸不大不小，身材很好，和照片一致。"
          ),
        },
        {
          name: this.$t("SIUG 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子看上去很小，服務一般，身子有點胖態度還是可以的 喜歡嫩的可以去試試..."
          ),
        },
        {
          name: this.$t("YEUNGD 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子長相甜美，一顆美人痣最為誘惑身材不錯，喜歡旅遊，性格溫柔開朗"
          ),
        },
        {
          name: this.$t("Changa 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子比較嫩，顏值比較高，就是皮膚有點黑。妹子最近才回上海，簡單服務陪浴口做。"
          ),
        },
      ];
      this.peiduilist = [
      {
          name: this.$t("Ming先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Boy先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("Steven先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("wong先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("bABU rAJ 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("COOO 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("CHUN 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Andy先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Benjamin 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Michael 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("Ethan 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },{
          name: this.$t("lau 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Shum 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Leung"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("CHEUNG"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Choi 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Yasu 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Wong 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("TimLi 先生"),
          tel: "(65****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Ng 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("Ma 先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("Lin先生"),
          tel: "(852****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
      ];
      this.getAddress();
    },
  },
  created() {
    this.createList();
   
    // this.getPeiduilist();
    // this.getYuepaolist();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #16151b;
  // height: 100%;
}
.nav-bar {
  // background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
  background-color: #16151b;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  font-size: 35px;
  color: #f3c7a2;
  font-weight: bold;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.my-swipe {
  border-radius: 10px;
  height: 310px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 510px;
  text-align: center;
  background-color: #39a9ed;
}
.convention-hall {
  // display: flex;
  // flex-direction: column;
  // bottom: 20px;
  // background: #f2f2f5;
  // height: 100%;
}
.convention-item {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 3.5625rem;
}
.banner_img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
  color: #fff;
}
::v-deep .van-tabs__line {
  background-color: #f3c7a2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.describe {
  width: 100%;
  min-height: 9, 875rem;
  border-radius: 0.625rem;
  border: 1px solid #766350;
  margin: 0.625rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
}
.describe > ul {
  height: 5.1875rem;
  display: flex;
  align-items: flex-start;
}
.describe > ul > li:first-child {
  width: 7.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 0.1875rem solid #e6b98a;
}
.describe > ul li {
  height: 100%;
}
.describe > div {
  height: 3.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #e6b98a;
  line-height: 1.0rem;
  font-size: 0.75rem;
}
.describe > ul > li:first-child > div:first-child {
  font-size: 1rem;
  font-weight: 700;
}
.describe > ul > li:first-child div {
  color: #f3c7a2;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.describe > ul > li:first-child > div:nth-child(2) {
  font-size: 0.8125rem;
}
.describe > ul > li:first-child div {
  color: #f3c7a2;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.describe > ul > li:nth-child(2) {
  padding: 0 0 0 0.625rem;
  width: calc(100% - 7.6rem);
  font-size: 0.625rem;
  line-height: 0.85rem;
  color: #fff;
}
.describe > ul li {
  height: 100%;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}
::v-deep .van-tabs__nav {
  background-color: #16151b;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20px;
  // margin-top: 10px;
  color: #fff;
}
.address {
  width: 90%;
  margin: 0 auto;
}
.xuanfei {
  width: 100%;
  height: 9.75rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #766350;
  margin: 0.625rem 0 0.625rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
  position: relative;
  overflow: hidden;
}
.xuanfei .left-div {
  width: 12.9375rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.xuanfei .right-div {
  width: 7.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xuanfei .sign-i {
  position: absolute;
  right: -40px;
  top: 10px;
  background: #ff6056;
  font-size: 15px;
  padding: 10px 45px;
  font-style: normal;
  transform: rotate(45deg);
  color: #fff;
}
.xuanfei .left-div > div:first-child {
  background: linear-gradient(90deg, #efcba7, #dcab76);
  color: #8d5825;
  height: 1.25rem;
  padding: 0 1.25rem;
  border-radius: 0.625rem;
  line-height: 1.25rem;
  text-align: center;
  font-size: 1rem;
}
.xuanfei .left-div > div:nth-child(2) {
  margin: 0.625rem 0;
  padding-left: 0.625rem;
}
.xuanfei .left-div > div:nth-child(2) ul li {
  height: 1.125rem;
  line-height: 1.125rem;
  text-align: left;
  font-size: 0.875rem;
  color: #fff;
}
.xuanfei .left-div > div:nth-child(3) ul {
  display: flex;
}
.xuanfei .left-div > div:nth-child(3) ul > li:first-child {
  margin-right: 0.625rem;
}
.xuanfei .left-div > div:nth-child(3) ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.xuanfei .left-div > div:nth-child(3) ul li img {
  height: 0.8125rem;
  margin-right: 0.125rem;
}
.xuanfei .left-div > div:nth-child(3) ul li span {
  font-size: 0.8125rem;
  color: #696969;
}
.xuanfei .right-div img {
  width: 6.5rem;
  height: 6.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.scroll-cmp {
  width: 100%;
  height: 12.9375rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #766350;
  margin: 0.625rem 0 6.25rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem;
  position: relative;
  overflow: hidden;
}
.scroll-cmp .van-swipe-item {
  height: 1.125rem;
}
.scroll-cmp .van-swipe-item div:first-child span {
  color: #e6b98a;
  font-size: 0.9375rem;
}
.scroll-cmp .van-swipe-item div:nth-child(2) span {
  color: #fff;
  font-size: 0.7125rem;
}
</style>
