module.exports = {
    "防骗指南": "Anti-Scam Guide",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "All the resources on this platform are real-name system. Both the younger sister and the younger brother are VIP members, please respect both parties, please don't speak rudely and have no quality, if you find out, you will be disqualified from dating, please remember! , The important thing is said three times! ! !",
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "Miss Sister will not charge any fees. You only need to complete three rewarding tasks on the platform to get a dating spot to enjoy the same-city dating resources. You need to take care of the house opening expenses yourself!",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "The girl has gone downstairs and asked to pay [full payment to go upstairs] are undoubtedly liars, please come to the platform immediately to contact the reception customer service to report!",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "Friendly reminder: Teachers, receptionists, and dispatchers will not add customer contact information privately. The working hours are 13.00~01.00. The rest of the time will not handle business. If someone sends you a message during off-duty time, please do not believe it and avoid it We are not responsible for any loss of funds! ! !",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "Anyone who asks to download other software is a liar, please take a screenshot immediately to the platform to contact the reception customer service to report!",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "This platform is responsible for the pleasure-seeking experience of every VIP member. If you encounter any problems, please come to the platform to contact the reception customer service to report in time. After the official verification, the unlocking consumption will be refunded!",
    "预约大厅": "Reservation lobby",
    "会员视频": "member video",
    "骑在身上大屌套弄": "Riding Big Cock Fucking",
    "选妃中心": "Concubine selection center",
    "新加入": "newly joined",
    "附近的人": "People nearby",
    "为您发现同城炮友 999+ 位": "Discover 999+ friends with sex in the same city for you",
    "刷新成功": "Refresh successfully",
    "为您发现同城炮友": "Find friends with sex in your city",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "All resources on this platform are real, safe, reliable, and airborne nationwide. Choose your favorite girl and start dating anytime, anywhere~",
    "联系专属接待客服": "Contact the exclusive reception customer service",
    "打赏可见": "open card visible",
    "寶衡传媒打分": "Zhongcai Media Score",
    "美女颜值": "Beauty value",
    "详细地址：已隐藏 联络客服打赏可见": "Detailed address: hidden, contact Xiaomi to open the card to see",
    "联系方式：已隐藏 联络客服打赏可见": "Contact information: hidden, contact Xiaomi and open the card to see",
    "基本信息 20岁,身高 175": "Basic Information {age}age, height {height}",
    "来自": "from",
    "服务项目 激活可见": "Service Item Activate Visible",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "I wish the members, date beautiful girls every day, and be a groom every night!",
    "立即约她": "ask her out now",
    "请联系专属客服接待": "Please contact the exclusive customer service reception",
    "寶衡传媒": "Zhongcai Media",
    "华人第一福利品牌": "Harbor first welfare brand",
    "为了会员权益，网站只展示": "For the benefit of members, the website only displays",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "The resources are updated from time to time only to show strength, if you need to inquire and reserve more resources, please contact the superior reception",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "All resources on this platform are real, safe, and reliable. National airborne, same-city dating, choose your favorite girl, and meet you at zero distance. Come join us",
    "寶衡资源": "Zhongcai Media",
    "昵称": "Nick name",
    "类型": "type",
    "服务项目: 激活可见": "Service Items: Activate Visible",
    "已认证": "verified",
    "实时配对": "real-time pairing",
    "约炮心得": "Dating experience",
    "服务条款": "Terms of Service",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "This platform is the most authentic peripheral + business escort + city passion in the whole network. In order to ensure the personal privacy of each user, customers can only join by contacting the receptionist or the real-name recommendation of senior members of the platform",
    "有哪些资源?": "What resources are there?",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "Internet celebrities, models, flight attendants, young models, college students, only you can't think of it, and you can't do it without this platform",
    "服务范围": "Service area",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "Services: Sex 3 times, Creampie without condom, Deep Throat, Roaming, Duron, Massage",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "Can take pictures as souvenirs: close-up of private parts, sex recording, blowjob recording",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "Uniforms can be specified: Nurse-Maid-Stewardess-Secretary-Policewoman-Catwoman",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "Can be bundled and lightly abused: use all kinds of sexual props for men and women together",
    "北京": "Beijing",
    "上海": "Shanghai",
    "广州": "Guangzhou",
    "深圳": "Shenzhen",
    "南京": "Nanjing",
    "长沙": "Changsha",
    "重庆": "Chongqing",
    "西安": "Xi'an",
    "丁先生": "Mr. Ding",
    "通过本站已预约......": "Appointment has been made through this website...",
    "朱先生": "Mr. Zhu",
    "正在服务中......": "In service...",
    "李先生": "Mr. Li",
    "郭先生": "Mr. Guo",
    "路先生": "Mr. Lu",
    "郝先生": "Mr. Hao",
    "赵先生": "Mr. Zhao",
    "祝先生": "Mr. Zhu",
    "牛先生": "Mr. Niu",
    "张先生": "Mr. Zhang",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "Contacted and made an appointment to ask me to go to her house to fuck her, the skin is still tight",
    "颜值很高，服务很专业，还会热舞...": "The appearance is very high, the service is very professional, and there are hot dances...",
    "床上满分 可一字马 性格超好 氵超多": "Perfect score in bed, but a word of horse, super good personality, too much",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "Introduced by a friend, the girl can be young and beautiful, and the service is Dongguan-style",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "I happened to be free today, so I just went to verify it, so I made an appointment. When we met, I found that the chest was neither big nor small, and the figure was very good, which was consistent with the photo",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "When I entered the door, I saw mature women. I like young women. Wearing high heels and stepping on them will make your figure more prominent. Simply wash and start work, the mouth is very comfortable, and the sister's mouth is full of wrapping",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "Very attentive, people are more charming, more coquettish, soft-spoken, coquettish words in one set, make people more excited, and can arouse men's sexual desire",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "The girl looks very small, the service is mediocre, she is a bit fat and her attitude is okay. If you like tenderness, you can try it...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "The girl looks sweet, and a beauty mole is the most attractive. She has a good figure, likes to travel, and has a gentle and cheerful personality",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "The girl is relatively tender and has a relatively high appearance, but her skin is a bit dark. The girl just returned to Shanghai recently, and she simply serves as a bathmate",
    "登录": "Login",
    "请输入用户名": "Please enter username",
    "请输入密码": "Please enter password",
    "忘记密码？": "Forgot password?",
    "没有账户？马上注册": "Don't have an account? Register now",
    "注册": "Register",
    "请输入用户名(6-12个英文字母或数字)": "Please enter username (6-12 letters or numbers)",
    "请输入登录密码(6-12个英文字母或数字)": "Please enter login password (6-12 letters or numbers)",
    "我已经知晓并同意'开户协议'各项条约": "I have read and agreed to the 'Account Opening Agreement'",
    "请输入登记码": "Please enter invitation code",
    "请勾选下方开户协议": "Please check the 'Account Opening Agreement' below",
    "预约做单": "Make an appointment to trade",
    "距 第": "Remaining",
    "期 截止": "days until deadline",
    "第": "Round",
    "期": "",
    "糖心": "Big",
    "烟花": "Small",
    "双份": "Double",
    "单份": "Single",
    "双": "Double",
    "单": "Single",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "This data will be used to rank the popularity of the rewarded anchor in the live broadcast room. Official customers can open live broadcast room access for free after completion",
    "与主播私下约啪": "Arrange a private meeting with the anchor",
    "任务单": "Task list",
    "可用余额": "Available balance",
    "元": "Points",
    "打赏": "Tip",
    "当前选号": "Current selection",
    "打赏金额": "Tip amount",
    "请输入金额": "Please enter amount",
    "总共打赏": "Total tips",
    "合计": "Total",
    "清空订单": "Clear order",
    "确认打赏": "Confirm tip",
    "期号": "Issue number",
    "正确打赏": "Correct tip",
    "约炮数据一": "Flirt data one",
    "约炮数据二": "Flirt data two",
    "约炮数据三": "Flirt data three",
    "高级VIP数据": "Advanced VIP data",
    "填写收款卡": "Fill in payment card",
    "请输入您的真实信息 以便积分兑换": "Please enter your payment card information",
    "请输入真实银行卡号": "Please enter your real bank card number",
    "请选择银行": "Please select a bank",
    "号码": "Bank Number",
    "银行": "Bank Name",
    "英文名": "English Name",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "Dear user, in order to ensure the safety of your funds, please confirm your real name and set a withdrawal password. If your name is not consistent with the account name, you will not be able to withdraw funds",
    "确认提现": "Confirm withdrawal",
    "任务记录": "Task record",
    "数据为空": "Data is empty",
    "打赏积分": "Tip points",
    "下单时间": "Order time",
    "结算时间": "Settlement time",
    "充值": "Deposit",
    "提现": "Withdrawal",
    "我的钱包": "My wallet",
    "详情": "Details",
    "余额(元)": "Balance ($)",
    "个人报表": "Personal report",
    "账户明细": "Account details",
    "打赏记录": "Tip record",
    "个人中心": "Personal center",
    "信息公告": "Announcement",
    "在线客服": "Online customer service",
    "请完成任务单后进入": "Please enter after completing the task list",
    "请联系客服充值": "Please contact customer service for recharge",
    "请设置收款卡": "Please set up a collection card",
    "功能已禁用": "Function is disabled",
    "登录/注册": "Log in/Register",
    "登录可享受更多服务": "Logging in allows you to enjoy more services",
    "基本信息": "Basic Information",
    "头像": "Profile Picture",
    "选择头像": "Select Profile Picture",
    "确定": "Confirm",
    "未设置": "Not Set",
    "性别": "Gender",
    "男": "Male",
    "女": "Female",
    "未知": "Unknown",
    "绑定信息": "Binding Information",
    "已绑定": "Bound",
    "无": "None",
    "系统公告": "System Announcement",
    "盈利金额(元)": "Profit Amount (RMB)",
    "盈利计算公式 : 盈利金额 - 任务金额": "Profit Calculation Formula: Profit Amount - Task Amount",
    "任务金额": "Task Amount",
    "充值金额": "Top-Up Amount",
    "提现金额": "Withdrawal Amount",
    "中奖金额": "Winnings Amount",
    "蜜獾娱乐": "MiHuan Entertainment",
    "联系": "Contact",
    "全天7 * 24小时竭诚为您服务": "We are available 24/7 to serve you",
    "收款卡信息": "Collection Card Information",
    "SG 添加": "SG Add",
    "提示 : 请分别在 HK / SG 选择属于你的信息": "Reminder : Please select your information in HK / SG respectively",
    "请设置姓名后再绑定银行卡": "Please set up your name before binding your bank card",
    "请设置提现密码后再绑定银行卡": "Please set up your withdrawal password before binding your bank card",
    "修改登录密码": "Change Login Password",
    "保存": "Save",
    "旧密码": "Old Password",
    "请输入您的旧密码": "Please enter your old password",
    "新密码": "New Password",
    "请输入您的新密码": "Please enter your new password",
    "请填写完整": "Please fill in completely",
    "两次密码输入不一致": "The two passwords entered do not match",
    "修改真实姓名": "Change Real Name",
    "姓名": "Name",
    "请输入真实姓名": "Please enter your real name",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "To ensure the security of your account, your real name should match the name on the linked bank card",
    "请勿重复设置": "Please avoid duplicate settings",
    "请输入姓名": "Please enter your name",
    "设置资金密码": "Set fund password",
    "请输入资金密码": "Please enter the fund password",
    "请再次输入资金密码": "Please re-enter the fund password",
    "两次密码不一致": "The passwords do not match",
    "性别修改": "Modify gender",
    "设置": "Set",
    "基本信息设置": "Basic information settings",
    "登录密码": "Login password",
    "资金密码": "Fund password",
    "退出登录": "Logout",
    "提现密码已设置，需要修改请联系客服": "The withdrawal password has been set. Please contact customer service for any modifications",
    "已设置": "Already set",
    "提现中心": "Withdrawal center",
    "提现审核纪录": "Withdrawal records",
    "提现金额 (元)": "Withdrawal amount",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "Time of arrival: Generally, it takes about 5 minutes for the withdrawal to be processed and it can arrive within 2 minutes",
    "说明": "Explanation",
    "余额": "Balance",
    "马上提现": "Withdraw now",
    "请填写正确的金额": "Please enter the correct amount",
    "金额": "Amount",
    "提交时间": "Submission time",
    "审核时间": "Review time",
    "没有更多了": "No more",
    "播放": "Play",
    "次播放": "Times played",
    "热门推荐": "Popular recommendations",
    "账号下线": "Account offline",
    "请充值后观看视频": "Please recharge before watching videos",
    "直播间": "Live room",
    "官方认证女神": "Official certified goddess",
    "已缴纳20000保证金": "Has paid a 20,000 deposit",
    "地区": "Region",
    "签名": "Signature",
    "获取联系方式": "Get contact information",
    "温馨提示": "Friendly reminder",
    "联络专属接待员激活权限": "Contact data teacher to activate permissions",
    "取消": "Cancel",
    "标题": "Title",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "Service items: 3 times of lovemaking, bareback creampie, deep throat, roaming, poison dragon, massage",
    "首页": "Home",
    "预约": "Appointment",
    "我的": "My",
    "注": "Note",
    "金额错误": "Incorrect amount",
    "请选号": "Please select a number",
    "请填写金额": "Please enter the amount",
    "余额不足，请联系客服充值": "Insufficient balance, please contact customer service to recharge",
    "请联系管理员领取该任务": "Please contact the administrator to receive the task",
    "开奖成功，期号": "Lottery successful, period number:",
    "玫瑰": "Rose",
    "火箭": "Rocket",
    "HK 添加": "HK Add",
    "填写转数快": "Fill in FPS",
    "请输入您的转数快信息": "Please enter your FPS information",
    "转数快/户口号码": "FPS/Account Number",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合": "According to UnionPay's risk control requirements, the verification frequency for the same card within 24 hours cannot exceed 4 times. Thank you for your cooperation",
    "确认": "Confirm",
    "已存在提款方式，请勿重复绑定": "Withdrawal method already exists, please do not bind it again",
    "公告": "Announcement",
    "東區": "Eastern District",
    "后生女": "young girl",
    "通過本站已預約......": "Booked through this site......",
    "正在服務中......": "In Service...",
    "中西區": "Central and Western District",
    "御姐": "royal sister",
    "灣仔區": "Wan Chai District",
    "火辣": "hot",
    "南區": "South area",
    "油尖旺區": "Yau Tsim Mong District",
    "人妻": "wife",
    "深水埗區": "Sham Shui Po District",
    "少婦": "young woman",
    "離島區": "Islands District",
    "元朗區": "Yuen Long District",
    "葵青區": "Kwai Tsing District",
    "沙田區": "Sha Tin District",
    "西貢區": "Sai Kung District",
    "北區": "North District",
    "九龍城區": "Kowloon City District",
    "觀塘區": "Kwun Tong District",
    "荃灣區": "Tsuen Wan District",
    "屯門區": "Tuen Mun District",
    "大埔區": "Tai Po District",
    "顏值很高，服務很專業，還會熱舞...": "The appearance is very high, the service is very professional, and there are hot dances...",
    "朋友介紹的，妞可以年輕漂亮，服務夠莞式。": "Introduced by a friend, the girl can be young and beautiful, and the service is Dongguan-style.",
    "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足": "When I entered the door, I saw mature women. I like young women. Wearing high heels and stepping on them will make your figure more prominent. Simply wash and start work, the mouth is very comfortable, and the sister's mouth is full of wrapping",
    "床上滿分 可一字馬 性格超好 氵超多": "Perfect score in bed, but a word of horse, super good personality, too much",
    "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾": "Very attentive, people are more charming, more coquettish, soft-spoken, coquettish words in one set, make people more excited, and can arouse men's sexual desire",
    "聯繫約好時間叫我去她家操她，皮膚還是很緊緻": "Contacted and made an appointment to ask me to go to her house to fuck her, the skin is still tight",
    "此網站暫時只展示": "This site only displays",
    "資源，並且不定期更新只為展示實力，如需查詢預約登記更多女仔，請聯繫你的接待員": "Resources, and are updated from time to time just to show strength, if you need to inquire about making an appointment to register more girls, please contact your receptionist",
    "本平台是一个主播平台，所有女孩都经过实名认证，真实丶安全丶可靠，每个区域都可以提供配对，选择一个心仪的她，陪你零距离邂逅，快点来加入吧!": "All the resources on this platform are real, safe and reliable. Hong Kong-wide services, matching in the same area, pick a favorite distance, and meet you at zero distance. Join now",
    "积分兑换":"Redeem",
    "获取积分":"Earn Points",
    "我的积分":"My Scores",
    "全部":"Total",
    "请输入英文名":"Please enter English name",
    "请输入 转数快/户口号码":"Please enter FPS/Account Number",
}