<template>
	<div class="container page">
		<!-- this.lottery.name -->
		<van-nav-bar :title="title" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
			<template #right>
				<van-popover v-model="showPopover" :actions="actions" @select="onSelect" theme="dark">
					<template #reference>
						<div class="right" @click="showPopover ? (showPopover = false) : (showPopover = true)">{{ $t("預約打賞")
						}}</div>
					</template>
				</van-popover>
			</template>
		</van-nav-bar>
		<div class="record">
			<div class="period">
				<van-image class="cover" src="img/photo_2022-11-12_21-53-12.jpg">
					<template v-slot:loading>
						<van-loading type="spinner" />
					</template>
				</van-image>
				<span style="font-size: 10px;">{{ $t("距 第") }}</span>
				<span class="period-number">{{ this.lottery.next_expect }}</span>
				<span style="font-size: 10px;">{{ $t("編碼 截止") }}</span>
				<div class="next-number">
					<!-- <span>{{ this.lottery.next_expect }}</span> -->
					<van-count-down :time="time" @finish="check()" />
				</div>
			</div>
			<div class="linear-gradient"
				style="background: linear-gradient(to right,rgba(126, 86, 120, 0),rgb(230, 195, 161),rgba(126, 86, 120, 0));">
			</div>
			<div class="recent">
				<div class="kuaisan-ball left">
					{{ $t("第") }}
					&nbsp;
					<span class="period-span">{{ this.lottery.now_expect }}</span>
					&nbsp;
					{{ $t("編碼") }}
					&nbsp;&nbsp;
					<span v-if="lottery_list[0]" class="res-des middle">
						{{ lottery_list[0].opencode }}
					</span>
					<!-- &nbsp;&nbsp;&nbsp;
					<span class="res-des middle" v-if="lottery_list[0]">
						{{ ((lottery_list[0].opencode[0] + lottery_list[0].opencode[1] + lottery_list[0].opencode[2]) >= 10)
							?
							$t("大") : $t("小") }}
					</span>
					&nbsp; -->
					<!-- <span v-if="lottery_list[0]" class="res-des middle">{{ (lottery_list[0].opencode[0] +
						lottery_list[0].opencode[1] + lottery_list[0].opencode[2]) % 2 === 0 ? $t('双') : $t("单") }}</span>

					<span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "玫瑰" : "糖心"}}</span>
		  <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "烟花" : "火箭"}}</span> -->
				</div>
				<van-icon name="arrow-down" :class="{ up: active, down: !active }"
					@click="active ? (active = false) : (active = true)" />
			</div>
		</div>
		<div class="history_popup"></div>
		<div class="wrapper">
			<div class="options-bar">
				<div class="game">

					<!-- <div class="tips">
						<p class="odds">【{{ this.lottery.desc }}】</p>
						<div class="play-tip">
							<van-icon name="more-o" />
							<span class="span-text" @click="playgame = true">玩法提示</span>
							<span class="span-text" @click="$router.push({ path: '/GameRecord' })">任务记录</span>
							<van-popup class="mask" get-container="body" v-model="playgame">
								<div class="play-type-tip">
									<div class="title">玩法规则</div>
									<div class="wrapper">
										<div class="item">
											<van-icon name="info-o" />
											<div class="content">
												<p class="content-title">玩法提示</p>
												<p class="content-detail">从可选和值形态里面选择号码进行下注</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="comment-o" />
											<div class="content">
												<p class="content-title">中奖说明</p>
												<p class="content-detail">三个开奖号码总和值11~18 为大;总和值3~ 10为小;</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="description" />
											<div class="content">
												<p class="content-title">投注范例</p>
												<p class="content-detail">投注方案：小 开奖号码：123,即中小</p>
											</div>
										</div>
									</div>
								</div>
							</van-popup>
						</div>
					</div> -->



					<div class="linear-gradient" style="
		              background: linear-gradient(
		                to right,
		                rgba(126, 86, 120, 0),
		                rgb(230, 195, 161),
		                rgba(126, 86, 120, 0)
		              );
		            "></div>
					<div class="sumValueTwoSides">
						<div class="rectangle large" :class="{ active: choose[v.type] }"
							v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name)">
							<div class="wrapper">
								<div class="content">
									<span class="name-text large">{{ v.name }}</span>
									<span class="odd-text large"><img :src="v.lot_icon" alt="" /></span>
								</div>
							</div>
						</div>
					</div>
					<div class="show-mall-div" :style="banner">
						<!-- <ul>
							<li><span>{{ $t("寶衡传媒") }}</span></li>
							<li>{{ $t("本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限") }}</li>
							<li>{{ $t("与主播私下约啪") }}</li>
						</ul> -->
					</div>
				</div>
			</div>
			<div class="bottom-bar">
				<div class="bar">
					<div class="left">
						<div class="item" @click="shopping ? (shopping = false) : (shopping = true)">
							<van-icon name="cart-o" class="jixuanico" />
							<span class="text">{{ $t("打賞列表") }}</span>
						</div>
						<div class="line"></div>
					</div>
					<div class="mid">
						<span class="text">{{ $t("可用余额") }}</span>
						<span class="text num">{{ this.userInfo.money }}</span>
						<span class="text">{{ $t("元") }}</span>
					</div>
					<div class="right" @click="jiesuan()">{{ $t('打赏') }}</div>
				</div>
				<div class="wrapper" :class="{ active: shopping }">
					<div class="item">
						<span class="label">{{ $t("当前选号") }}：</span>
						<div class="bet-number">{{ this.shopchoose }}</div>
						<van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
							@click="shopping ? (shopping = false) : (shopping = true)" />
					</div>
					<div class="item">
						<span class="label">{{ $t("打赏金额") }}：</span>
						<div class="amount-wrapper">
							<van-field v-model="money" type="number" min="0.001" :formatter="moneyFormatter"
								:placeholder="$t('请输入金额')" />
							<span class="label">{{ $t("元") }}</span>
						</div>
					</div>
					<div class="item">
						<div class="part">
							<span>{{ $t("总共打赏") }}</span>
							<span class="number">{{ this.formData.length }}</span>
							<span>{{ $t("注") }}</span>
						</div>
						<div class="part">
							<span>{{ $t('合计') }}</span>
							<span class="number">{{ this.formData.length * this.money }}</span>
							<span>{{ $t('元') }}</span>
						</div>
					</div>
				</div>
			</div>
			<van-popup v-model="jiesuanpage" get-container="body">
				<div class="confirm-order-modal">
					<div class="head van-hairline--bottom">
						<p class="text">{{ $t('打賞列表') }}</p>
					</div>
					<ui class="list">
						<li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
							<div class="main">
								<p class="bet-name">{{ v.name }}</p>
								<p class="detail-text">1注X{{ money }}{{ $t('元') }}={{ money }}{{ $t('元') }}</p>
							</div>
							<van-icon @click="clearChooes(v.type)" name="close" />
						</li>
					</ui>
					<div class="sub-bar">
						<van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t("清空订单") }}</van-button>
						<van-button class="item sub-btn" type="default" @click="doSub">{{ $t("确认打赏") }}</van-button>
					</div>
				</div>
			</van-popup>
			<van-popup v-model="active" position="top" :style="{ height: '70%' }">
				<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
					<div class="wrapper">
						<div class="item">
							<div class="left font-weight">{{ $t("編碼") }}</div>
							<div class="right font-weight">{{ $t("正确打赏") }}</div>
						</div>
						<div class="item" v-for="(v, key) in lottery_list" :key="key">
							<div class="left font-weight">{{ v.expect }}</div>
							<div class="right font-weight">
								<div class="kuaisan-ball left">
									<span class="res-des middle">
										{{ v.opencode }}
									</span>
									<!-- &nbsp;&nbsp;
									<span class="res-des middle"> {{ ((v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 10)
										? $t('大') : $t("小") }}
									</span>
									&nbsp;&nbsp;
									<span class="res-des middle">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2])
										% 2 === 0 ? $t('双') : $t("单") }}
									</span> -->
								</div>
							</div>
						</div>
					</div>
				</van-pull-refresh>
			</van-popup>
		</div>
	</div>
</template>

<script>
var time;
var count = 0;
export default {
	data() {
		return {
			showPopover: false,
			jiesuanpage: false,
			actions: [{ text: this.$t("约炮数据一") }, { text: this.$t("约炮数据二") }, { text: this.$t('约炮数据三') }, { text: this.$t("高级VIP数据") }],
			//   onSelect: (action) => Toast(action.text),
			domain: this.serviceUrl,
			choose: {
				大: false,
				小: false,
				单: false,
				双: false,
				3: false,
				4: false,
				5: false,
				6: false,
				7: false,
				8: false,
				9: false,
				10: false,
				11: false,
				12: false,
				13: false,
				14: false,
				15: false,
				16: false,
				17: false,
				18: false
			},
			playgame: false,
			shopping: false,
			isLoading: false,
			play: {},
			lottery_peilv_list: {},
			lottery_list: [],
			lottery_get_one_list: {},
			active: false,
			userInfo: {},
			lottery: {},
			shanzi_1: '0',
			shanzi_2: '0',
			shanzi_3: '0',
			sum: 0,
			size: '',
			double: '',
			time: 0,
			shopchoose: this.$t("未选择"),
			gameitem: '',
			formData: [],
			money: '',
			kjtitle: [
				{
					id: 'm',
					name: this.$t("玫瑰")
				},
				{
					id: 't',
					name: this.$t("糖心")
				},
				{
					id: 'h',
					name: this.$t("火箭")
				},
				{
					id: 'y',
					name: this.$t("烟花")
				}
			],
			title: ''
		};
	},
	methods: {
		moneyFormatter(value) {
			return value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
		},
		onSelect(action) {
			this.$toast(action.text);
			console.log(this.lottery)
			const params = {
				key: action.text === this.$t('约炮数据一') ? 'game1' : action.text === this.$t("约炮数据二") ? 'game2' : action.text === this.$t("约炮数据三") ? 'game3' : 'game4',
				id: action.text === this.$t("约炮数据一") ? '10' : action.text === this.$t("约炮数据二") ? '12' : action.text === this.$t("约炮数据三") ? '13' : '11'
			};
			this.$router
				.push({
					path: '/Lottery?key=' + params.key + '&id=' + params.id
				})
				.then(() => {
					this.title = action.text;
					this.getUserInfo();
					this.getLotteryInfo();
					this.getLotteryList();
					this.getBanner();
				});
		},
		searchList(indexKey) {
			return this.kjtitle.filter(item => {
				if (item.id.includes(indexKey)) {
					return item;
				}
			});
		},
		back() {
			//   return window.history.back();
			this.$router.push({ path: '/Game' });
		},
		doSub() {
			if (this.money === '0') {
				this.$toast(this.$t('金额错误'));
				return false;
			}
			if (this.formData.length === 0) {
				this.$toast(this.$t("请选号"));
				return false;
			} else if (this.money === '') {
				this.$toast(this.$t("请填写金额"));
				return false;
			} else {
				if (this.userInfo.money - this.money * this.formData.length < 0) {
					this.$toast(this.$t('余额不足，请联系客服充值'));
					return false;
				} else {
					this.$http({
						method: 'post',
						data: {
							item: this.gameitem,
							money: this.money,
							lid: this.lottery.id,
							mid: this.userInfo.id,
							expect: this.lottery.now_expect,
							key: this.lottery.key
						},
						url: 'game_place_order'
					}).then(res => {
						if (res.code === 200) {
							this.$toast(res.msg);
							this.allClear();
							this.getUserInfo();
						} else if (res.code === 401) {
							this.$toast(res.msg);
						}
					});
					return true;
				}
			}
		},
		allClear() {
			for (var i = 0; i < this.formData.length; i++) {
				this.choose[this.formData[i]['type']] = false;
			}
			this.formData.length = 0;
			this.money = '';
			this.shopchoose = this.$t('未选中');
			this.gameitem = '';
			this.shopping = false;
			this.jiesuanpage = false;
		},
		jiesuan() {
			if (this.formData.length === 0) {
				this.$toast(this.$t('请选号'));
				return false;
			} else if (this.money === '') {
				this.$toast(this.$t('请填写金额'));
				return false;
			} else {
				this.jiesuanpage ? (this.jiesuanpage = false) : (this.jiesuanpage = true);
			}
		},
		clearChooes(type) {
			for (var i = 0; i < this.formData.length; i++) {
				if (type === this.formData[i]['type']) {
					this.formData.splice(i, 1);
					this.choose[type] = false;
				}
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]['name'];
						this.gameitem = this.formData[j]['type'];
					} else {
						this.shopchoose += ',' + this.formData[j]['name'];
						this.gameitem += ',' + this.formData[j]['type'];
					}
				}
			} else {
				this.shopchoose = this.$t('未选中');
				this.gameitem = '';
				this.shopping = false;
			}
			if (this.formData.length === 0) {
				this.jiesuanpage = false;
			}
		},
		choosePlay(type, name) {
			if (this.choose[type] === true) {
				this.choose[type] = false;
				for (var i = 0; i < this.formData.length; i++) {
					if (type === this.formData[i]['type']) {
						this.formData.splice(i, 1);
					}
				}
			} else if (this.choose[type] === false) {
				this.formData.push({ name: name, type: type });
				this.choose[type] = true;
			}
			if (this.formData.length === 1) {
				this.shopping = true;
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]['name'];
						this.gameitem = this.formData[j]['type'];
					} else {
						this.shopchoose += ',' + this.formData[j]['name'];
						this.gameitem += ',' + this.formData[j]['type'];
					}
				}
			} else {
				this.shopchoose = this.$t("未选中");
				this.gameitem = '';
				this.shopping = false;
			}
		},
		check() {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				time = window.setInterval(() => {
					setTimeout(() => {
						this.getUserInfo();
						this.getLotteryInfo();
						this.getLotteryList();
						this.getBanner();

						count++;
						if (count > 5) {
							clearInterval(time);
							count = 0;
						}
					}, 0);
				}, 300);
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("刷新成功"));
				this.getLotteryList();
				this.isLoading = false;
			}, 200);
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryPeilv() {
			this.$http({
				method: 'get',
				data: { id: this.$route.query.id },
				url: 'lottery_get_peilv'
			}).then(res => {
				if (res.code === 200) {
					this.lottery_peilv_list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryList() {
			this.$http({
				method: 'get',
				data: { key: this.$route.query.key },
				url: 'lottery_get_one_list'
			}).then(res => {
				if (res.code === 200) {
					this.lottery_list = res.data;
					this.getLotteryPeilv();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryInfo() {
			this.$http({
				method: 'get',
				data: { key: this.$route.query.key },
				url: 'lottery_get_info'
			}).then(res => {
				// console.log(res.data)
				if (res.code === 200) {
					if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
						this.$toast(this.$t("请联系管理员领取该任务"));
						this.$router.push({ path: '/Home' });
						return false;
					}
					this.lottery = res.data;
					this.time = res.data.second * 1000;

					if (this.time / 1000 === 59) {
						this.$toast(this.$t("開獎成功，編碼") + '：' + this.lottery.now_expect);
					}
					// this.shanzi_1 = 'img/lottery/shaizi/' + res.data?.opencode[0] + '.png';
					// this.shanzi_2 = 'img/lottery/shaizi/' + res.data?.opencode[1] + '.png';
					// this.shanzi_3 = 'img/lottery/shaizi/' + res.data?.opencode[2] + '.png';

					// this.sum = this.searchList(res.data.opencode[0])[0]['name'] +'\xa0'+ this.searchList(res.data.opencode[1])[0]['name'] +'\xa0'+ this.searchList(res.data.opencode[2])[0]['name'];
					this.sum = Number(res.data.opencode[0]) + Number(res.data.opencode[1]) + Number(res.data.opencode[2]);
					if (this.sum >= 11 && this.sum <= 18) {
						this.size = this.$t("玫瑰");
					} else if (this.sum >= 3 && this.sum <= 10) {
						this.size = this.$t("糖心");
					}
					// console.log(this.sum)
					if (this.sum % 2 === 0) {
						this.double = this.$t("烟花");
					} else {
						this.double = this.$t('火箭');
					}
					console.log(res.data.name)
					this.title = res.data.name;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getBanner() {
			this.$http({
				method: 'get',
				url: 'get_banner'
			}).then(res => {
				this.banner = {backgroundImage : "url(" + res.data + ")"}
			});
		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' });
		} else {
			this.getUserInfo();
			this.getLotteryInfo();
			this.getLotteryList();
			this.getBanner();
		}
	},
	destroyed() {
		clearInterval(time);
	}
};
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';

.nav-bar .right {
	padding-left: 8px;
	padding-right: 8px;
	color: #fff;
	font-size: 28px;
	border-radius: 10px;
	border: 2px solid #fff;
	line-height: 60px;
}

.record {
	//   padding-left: 20px;
	//   padding-right: 20px;
	//   background-color: #836045;
	//   box-shadow: 0 2px 2px 0 #cacaca;
	z-index: 1;
	color: #fff;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	background-color: #16151b;
	border-top: 0.6rem solid #836045;
	border-bottom: 0.6rem solid #81644f;
}

.record .period {
	display: flex;
	align-items: center;
	padding: 0.2rem 0;
	font-size: 12px;
	border-bottom: 0.1rem solid #e6b98a;
}

.record .period .cover {
	width: 60px;
	height: 60px;
	-o-object-fit: cover;
	object-fit: cover;
	margin-right: 1rem;
}

.record .period .period-number {
	//   flex: 1;
	margin-left: 20px;
	margin-right: 10px;
	height: 50px;
	line-height: 50px;
	font-size: 0.95rem;
	font-weight: 700;
	// color: #000;
}

.van-count-down {
	color: #ff253f;
	font-size: 1.1rem;
	//   margin-top: 10px;
	margin-left: 1rem;
	float: right;
}

.linear-gradient {
	width: 100%;
	height: 2px;
}

.record .recent {
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	//   height: 110px;
}

.recent .kuaisan-ball {
	font-size: 0.7rem;
}

.recent .kuaisan-ball .period-span {
	color: #f3c7a2;
}

.recent .kuaisan-ball .res-des.middle {
	font-size: 0.8rem;
}

.kuaisan-ball .left {
	justify-content: flex-start;
}

.kuaisan-ball {
	flex: 1;
	display: flex;
	align-items: center;
}

.kuaisan-ball .res-img {
	width: 70px;
	height: 70px;
	margin-right: 30px;
}

.kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	// color: #000;
}

.kuaisan-ball .res-des.middle {
	// width: 15%;
	font-size: 1.125rem;
}

.van-icon {
	font-size: 40px;
}

.down {
	transition: all 0.5s;
}

.up {
	transform: rotate(180deg);
	transition: all 0.5s;
}

.wrapper {
	position: relative;
	flex: 1;
	overflow: hidden;
	background-color: #282828;
	color: #fff;
}

.options-bar {
	display: flex;
	align-items: center;
	height: calc(100% - 80px);
}

.options-bar .game {
	flex: 1;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
	display: flex;
	align-items: center;
	height: 100px;
	padding: 0 20px;
}

.options-bar .game .tips .odds {
	flex: 1;
	font-size: 35px;
	font-weight: 500;
	color: #ff253f;
}

.options-bar .game .tips .play-tip {
	display: flex;
	align-items: center;
	height: 100%;
}

::v-deep .van-icon-more-o {
	color: #ff253f;
	font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
	margin-left: 10px;
	font-size: 35px;
	font-weight: bolder;
	color: #ff253f;
}

.linear-gradient {
	width: 100%;
	height: 2px;
}

.sumValueTwoSides {
	display: flex;
	padding: 30px 0;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.rectangle {
	overflow: hidden;
}

.rectangle.large {
	margin: 0 0 30px 4%;
	width: 45%;
	border-radius: 10px;
	height: 3.125rem;
}

.rectangle .wrapper {
	position: relative;
	padding: 0 10px;
	background: #232127;
}

.rectangle .wrapper .content {
	position: absolute;
	/* display: flex; */
	top: 0;
	left: 0;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
	width: 100%;
	height: 100%;
	line-height: 3.125rem;
}

.odd-text {
	width: 0.9375rem;
	float: right;
	margin-top: 1.25rem;
	margin: 0.3125rem 0.625rem 0 0;
}

.odd-text>img {
	width: 100%;
}

.rectangle.large .wrapper {
	padding-bottom: 100%;
}

.rectangle .wrapper .content .name-text.large {
	font-size: 1rem;
	margin-left: 0.625rem;
}

.rectangle .wrapper .content .name-text {
	color: #fff;
	font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
	font-size: 25px;
}

.rectangle .wrapper .content .odd-text {
	text-align: center;
	color: #ff253f;
}

.bottom-bar {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100px;
	z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.bottom-bar .bar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100px;
	background-color: #836045;
	box-shadow: 0 0 20px 0 #cacaca;
	z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.bottom-bar .bar .left .item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100px;
	font-size: 20px;
}

.bottom-bar .bar .left .item .text {
	font-size: 22px;
	color: #fff;
}

.jixuanico {
	font-size: 45px;
}

.bottom-bar .bar .left .line {
	width: 2px;
	height: 50px;
	background: #dadada;
}

.bottom-bar .bar .mid {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.bottom-bar .bar .mid .text {
	font-size: 30px;
	font-weight: 500;
	color: #000;
}

.bottom-bar .bar .mid .text.num {
	margin: 0 5px;
	color: #ff253f;
}

.bottom-bar .bar .right {
	padding: 0 30px;
	margin: 0 30px;
	color: #fff;
	background: linear-gradient(270deg, #e6c3a1, #7e5678);
	font-size: 40px;
	font-weight: 500;
	height: 70px;
	line-height: 70px;
	border-radius: 50px;
}

.rectangle.active .wrapper {
	background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 35px;
}

::v-deep .van-popup {
	position: absolute;
}

::v-deep .van-overlay {
	position: absolute;
	background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
	top: -1px;
}

.wrapper .item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}

.wrapper .item .left {
	width: 40%;
	font-size: 0.7rem;
	text-align: center;
	font-weight: 500;
	//   color: #000;
}

.font-weight {
	font-weight: 700 !important;
}

.wrapper .item .right {
	flex: 1;
	display: flex;
	font-size: 0.7rem;
	justify-content: center;
	overflow: hidden;
	//   color: #000;
}

.wrapper .item .kuaisan-ball .left {
	justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
	margin-left: 39%;
	flex: 1;
	display: flex;
	align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
	width: 50px;
	height: 50px;
	margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	font-size: 0.7rem;
	//   color: #ff253f;
}

.wrapper .item .kuaisan-ball .res-des.middle {
	// width: 15%;
	//   font-size: 35px;
}

.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 650px;
	height: 700px;
	max-height: 50%;
	z-index: 10;
	border-radius: 30px;
	overflow: hidden;
	background-color: #fff;
	color: #000;
}

.play-type-tip .title {
	line-height: 90px;
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	text-align: center;
	color: #fff;
	font-size: 35px;
	font-weight: 500;
}

.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}

.play-type-tip .wrapper {
	height: calc(100% - 10px);
	background-color: transparent;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
	padding: 40px 50px;
	display: flex;
	align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
	color: #e6c3a1;
	font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
	margin-top: 22px;
	font-size: 35px;
	font-weight: 500;
	color: #000;
	line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
	margin-top: 5px;
	font-size: 22px;
	color: #000;
	line-height: 30px;
}

.play-type-tip .wrapper .item .content {
	flex: 1;
	margin-left: 30px;
}

.rectangle.active .wrapper {
	background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
	color: #fff !important;
}

.bottom-bar .wrapper {
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	padding: 20px 20px 10px 20px;
	height: 230px;
	background-color: #836045;
	z-index: 1;
	box-shadow: 0 0 10px 0 #cacaca;
	transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
}

.bottom-bar .wrapper.active {
	transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 65px;
}

.bottom-bar .wrapper .item .label {
	font-size: 30px;
	line-height: 30px;
	color: #fff;
}

.bottom-bar .wrapper .item .bet-number {
	flex: 1;
	margin: 0 16px;
	overflow: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	color: #ff253f;
	font-size: 30px;
	font-weight: 500;
	height: 40px;
	line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.van-cell {
	font-size: 30px;
	line-height: 50px;
}

.bottom-bar .wrapper .item .part {
	margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
	font-size: 30px;
	vertical-align: center;
	color: #000;
}

.bottom-bar .wrapper .item .part .number {
	margin: 0 5px;
	color: #ff253f;
	font-weight: 500;
}

::v-deep .van-field__control {
	color: #ff253f;
}

.confirm-order-modal {
	position: unset;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 0 20px 30px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 610px;
	height: 680px;
	max-height: 50%;
	z-index: 10;
	background-color: #fff;
	border-radius: 30px;
}

.confirm-order-modal .head {
	position: relative;
	height: 80px;
}

.confirm-order-modal .head .text {
	padding: 0 20px;
	height: 30px;
	line-height: 10px;
	text-align: center;
	font-size: 35px;
	font-weight: 500;
	color: #7e5678;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
	border-bottom-width: 2px;
}

.van-popup--center {
	border-radius: 30px;
}

.confirm-order-modal .list {
	flex: 1;
	padding: 0 10px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
	flex: 1;
	overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
	color: #ff253f;
	font-size: 35px;
	font-weight: 500;
	line-height: 0px;
	word-wrap: break-word;
	word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
	line-height: 0px;
	font-size: 25px;
	color: #979799;
}

.confirm-order-modal .list .lise-item {
	color: #ff253f;
}

.confirm-order-modal .sub-bar {
	display: flex;
	align-items: center;
	margin-top: 30px;
	justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
	min-width: 40%;
	height: 80px;
	text-align: center;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 35px;
	font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
	border: 2px solid #979799;
	color: #979799;
	background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
	background: linear-gradient(270deg, #e6c3a1, #7e5678);
	color: #fff;
	border: 0;
}

.next-number span {
	font-size: 35px;
	font-weight: 700;
	// color: #000;
	float: right;
}

.show-mall-div {
	width: 95%;
	margin: 10px auto;
	height: 9.5rem;
	// background-image: url(/img/photo_2023-03-20_jpg.jpg);
	background-size: cover;
	background-position: 50%;
	border-radius: 10px;
}

.show-mall-div>ul {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: rgba(0, 0, 0, 0.4);
}

.show-mall-div ul li {
	// display: flex;
	align-items: center;
	text-align: center;
	height: 3.875rem;
	padding-top: 0.625rem;
}

.show-mall-div ul>li:first-child span {
	font-size: 1.25rem;
	font-weight: 700;
	color: #fff;
}

.show-mall-div ul>li:nth-child(2) {
	font-size: 0.9375rem;
	color: #fff;
	font-weight: 700;
	padding: 0 0.625rem;
}

.show-mall-div ul>li:nth-child(3) {
	font-size: 1.25rem;
	color: #ffb94f;
	font-weight: 700;
	font-family: PingFangSC-Medium, PingFang SC;
}</style>
